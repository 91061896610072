import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'

import FramelessLayout from '../components/layouts/FramelessLayout'
import Header from '../components/shared/Header'
import Footer from '../components/shared/Footer'

const TeamPanoGrid = loadable(() => import('../components/shared/TeamPanoGrid'))

const AboutPage = ({ path, data }) => {
  const seo = {
    title: data.wordpressPage.acf.seo_meta_title || "",
    description: data.wordpressPage.acf.seo_meta_description || "",
    robotsIndex: data.wordpressPage.acf.seo_index ? 'index' : 'noindex',
    robotsFollow: data.wordpressPage.acf.seo_follow ? 'follow' : 'nofollow',
  }
  const { page_heading: pageHeading } = data.wordpressPage.acf
  return (
    <FramelessLayout>
      <Helmet
        title={seo.title}
        bodyAttributes={{
          class: 'has-logo-left',
        }}
      >
        <html lang="en"/>
                  { seo.description !== "" &&
          <meta name="description" content={seo.description} />
          }
          <meta name="robots" content={seo.robotsIndex + ", " + seo.robotsFollow} />
        <link rel='canonical' href='https://boldbreed.de/about/' />
      </Helmet>

      <div className='wrapper'>
        <div className='wrapper__lines'></div>

        <div className='wrapper__inner'>
          <Header path={path} />

          <section className='section-cols about'>
            <h2
              dangerouslySetInnerHTML={{
                __html: pageHeading?.replace(' ', ' <br />'),
              }}
            />
            <div className='section__entry static np'>
              <TeamPanoGrid />
            </div>
          </section>

          <Footer />
        </div>
      </div>
    </FramelessLayout>
  )
}

export default AboutPage

export const aboutQuery = graphql`
  query AboutPageQuery {
    wordpressPage(title: { eq: "About" }) {
      acf {
        page_heading
        seo_meta_title
        seo_meta_description
        seo_index
        seo_follow
      }
    }
  }
`
