import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Link, navigate } from 'gatsby'
import $ from 'jquery'
import SearchIcon from '../../../static/assets/images/search.svg'
import CloseIcon from '../../../static/assets/images/close.svg'

const Autocomplete = ({
  allArtists = new Map([]),
  onClose = () => {},
  hasIcon = false,
  isMobile = false,
}) => {
  const autoCompleteInputRef = useRef(null)
  const [state, setState] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: '',
  })

  useEffect(() => {
    autoCompleteInputRef.current.focus()
  }, [])

  const handleOnChange = e => {
    const userInput = e.currentTarget.value

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = Array
      .from(allArtists.keys())
      .filter(suggestion => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1)

    setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    })
  }

  const handleOnClose = () => {
    if (state.userInput) {
      setState({
        ...state,
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: '',
      })
      autoCompleteInputRef.current.focus()
      return
    }
    onClose()
  }

  const handleOnKeyDown = e => {
    const { filteredSuggestions, activeSuggestion } = state
    $('ul.suggestions a.suggestion-active').focus()
    $('input[type=search]').focus()
    // User pressed the enter key
    if (e.keyCode === 13) {
      if (filteredSuggestions[activeSuggestion]) {
        navigate(allArtists.get(filteredSuggestions[activeSuggestion]))
      }

      setState({
        ...state,
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      })
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      $('ul.suggestions').blur()
      if (activeSuggestion === 0) {
        return
      }

      setState({ ...state, activeSuggestion: activeSuggestion - 1 })
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        return
      }

      setState({ ...state, activeSuggestion: activeSuggestion + 1 })
    }
  }

  const renderCloseIcon = () => (
    <span onClick={e => handleOnClose(e)} className='clear-input'>
      <CloseIcon />
    </span>
  )

  const {
    activeSuggestion,
    filteredSuggestions,
    showSuggestions,
    userInput,
  } = state

  let suggestionsListComponent

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className='suggestions'>
          {filteredSuggestions.map((suggestion, index) => {
            let className

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = 'suggestion-active'
            }

            return (
              <Link
                className={className}
                key={suggestion}
                to={allArtists.get(suggestion)}
              >
                {suggestion}
              </Link>
            )
          })}
        </ul>
      )
    } else {
      suggestionsListComponent = (
        <div className='no-suggestions'>
          <span className='message'>
            Deine Suche hat leider keine Treffer ergeben. Überprüfe die
            Schreibweise oder{' '}
            <Link className='contact-link' to='/contact'>
              kontaktiere uns.
            </Link>
          </span>
        </div>
      )
    }
  }

  return (
    <Fragment>
      <input
        type='search'
        onChange={e => handleOnChange(e)}
        onKeyDown={e => handleOnKeyDown(e)}
        ref={autoCompleteInputRef}
        value={userInput}
        className={userInput ? 'has-input' : 'no-input'}
      />
      {hasIcon && !userInput && <SearchIcon />}
      {isMobile ? userInput && renderCloseIcon() : renderCloseIcon()}
      {suggestionsListComponent}
    </Fragment>
  )
}

export default Autocomplete
