import React, { useEffect, useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import $ from 'jquery'

import { convertSpacesToUnderscore } from '../../utils/utils'
import Autocomplete from './AutoComplete'
import BackBtn from '../../../static/assets/images/backBtn.svg'

const categories = new Map([
  [951, 'New'],
  [952, 'Commercial'],
  [953, 'Fiction']
])

function Header({ backLink, path, selectedCategory = '' }) {
  const [allArtists, setAllArtists] = useState(new Map())
  const [isSearching, setIsSearching] = useState(false)
  const data = useStaticQuery(graphql`
    query allWordpressWpArtistQuery {
      allWordpressWpArtist {
        edges {
          node {
            id
            acf {
              first_name
              second_name
            }
            artist_category
            artist_category_name
            path
          }
        }
      }
    }
  `)

  useEffect(() => {
    // setup mobile and desktop body classes
    const $win = $(window)
    const $body = $('body')
    let $scrollPosition = null

    const isMobile = {
      Android: function() {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function() {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        )
      },
    }

    if (isMobile.any() && $win.width() < 1200) {
      window.isMobile = true
      window.isDesktop = false
    } else {
      window.isMobile = false
      window.isDesktop = true
    }

    // Mobile Nav trigger
    $('.nav-trigger').on('click', function(e) {
      e.preventDefault()

      if (isMobile.any() && $win.width() < 1200) {
        const opened = $body.is('.has-menu-opened')

        if (opened) {
          $body.removeClass('has-menu-opened')
          $body.removeClass('is-fixed')
          window.scrollTo(0, $scrollPosition)
          $scrollPosition = null
          return
        } else {
          $scrollPosition = $(window).scrollTop()
          setTimeout(function() {
            $body.addClass('is-fixed')
          }, 400)
        }
      }

      $body.toggleClass('has-menu-opened')
    })

    setAllArtists(transformAllArtistData(data))
  }, [])

  const printCurrentClassOnActivePath = str => {
    if (path !== undefined && path.includes(str)) {
      return 'current'
    }

    return ''
  }

  const printCurrentClassOnActiveHome = () => {
    if (path !== undefined && path === '/') {
      return 'current'
    }

    return ''
  }

  const transformAllArtistData = ({ allWordpressWpArtist = {} }) => {
    if (allWordpressWpArtist && allWordpressWpArtist.edges) {
      return new Map (allWordpressWpArtist.edges.flatMap(({ node: artist }) => {
        return artist.artist_category.filter(category => categories.has(category)).map(category => {
          return [
            `${artist.acf.first_name} ${artist.acf.second_name} - ${categories.get(category)}`,
            artist.path + categories.get(category).toLowerCase()
          ]
        });
      }))
    }
  }

  const handleBackLink = backLink => {
    switch (backLink) {
      case 'commercial':
        return '/commercial-artists'
      
      case 'fiction':
        return '/fiction-artists'

      case 'new':
        return '/new-artists'

      default:
        return '/'
    }
  }

  return (
    <header className='header'>
      <div className='shell'>
        <div className='header__inner'>
          <Link
            to={handleBackLink(backLink)}
            state={{ from: selectedCategory }}
            className='btn-back'
          >
            <span>
              <BackBtn />
            </span>
          </Link>

          <a href='/' className='logo-left'>
            <span
              style={{
                backgroundImage: 'url(/assets/images/logo-boldgreed-dark.svg)',
              }}
            ></span>

            <span
              style={{
                backgroundImage: 'url(/assets/images/logo-boldgreed.svg)',
              }}
            ></span>
          </a>

          <a href='/' className='logo-grouped'>
            <span
              style={{
                backgroundImage: 'url(/assets/images/logo-boldgreed-dark.svg)',
              }}
            ></span>

            <span
              style={{
                backgroundImage: 'url(/assets/images/logo-boldgreed.svg)',
              }}
            ></span>
          </a>

          <a href='/' className='nav-trigger'>
            <span></span>

            <span></span>
          </a>

          <div className='header__content'>
            <div className='header__nav'>
              <nav className='nav'>
                <ul>
                  <li className={printCurrentClassOnActiveHome()}>
                    <Link to='/'>Home</Link>
                  </li>

                  <li
                    className={printCurrentClassOnActivePath('commercial-artists')}
                  >
                    <Link
                      to='/commercial-artists/'
                      className='js-animation'
                      data-direction='left'
                    >
                      Commercial Artists
                    </Link>
                  </li>

                  <li
                    className={printCurrentClassOnActivePath('fiction-artists')}
                  >
                    <Link
                      to='/fiction-artists/'
                      className='js-animation'
                      data-direction='right'
                    >
                      Fiction Artists
                    </Link>
                  </li>
                  <li
                    className={printCurrentClassOnActivePath('new-artists')}
                  >
                    <Link
                      to='/new-artists/'
                      // className='js-animation'
                      // data-direction='right'
                    >
                      New Artists
                    </Link>
                  </li>

                  <li className={printCurrentClassOnActivePath('about')}>
                    <Link to='/about/'>About</Link>
                  </li>

                  <li
                    className={`contact-link ${printCurrentClassOnActivePath(
                      'contact'
                    )}`}
                  >
                    <Link to='/contact/'>Contact</Link>
                  </li>
                  {!isSearching && (
                    <div
                      className={`header__search__icon ${
                        !isSearching ? 'fadeIn' : 'fadeOut'
                      }`}
                    >
                      <a
                        onClick={() => setIsSearching(true)}
                        className='btn-search'
                      ></a>
                    </div>
                  )}
                </ul>
              </nav>
            </div>
            {
              <div className='header__search__input__container is-mobile'>
                <div className='header__search__input'>
                  <Autocomplete
                    hasIcon={true}
                    isMobile={true}
                    allArtists={allArtists}
                  />
                </div>
              </div>
            }

            {/* <div className="header__lang">
                <nav className="nav-lang">
                  <ul>
                    <li>
                      <a href="#">DE</a>
                    </li>

                    <li className="current">
                      <a href="#">EN</a>
                    </li>
                  </ul>
                </nav>
              </div> */}
          </div>

          <a
            href='#'
            className='logo-right'
            style={{
              backgroundImage: 'url(/assets/images/logo-c-ya.svg)',
            }}
          />
        </div>
        {isSearching && (
          <div
            className={`header__search__input__container is-desktop ${
              isSearching ? 'fadeIn' : 'fadeOut'
            }`}
          >
            <div className='header__search__input'>
              <Autocomplete
                allArtists={allArtists}
                onClose={() => setIsSearching(false)}
              />
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
